import './App.css';
import './snow.css';
import React from 'react';
import {
  BrowserRouter,
  NavLink,
  Route,
  Routes
} from "react-router-dom";
import Christmas2023 from './Christmas2023';

function App() {
  return (
    // <BrowserRouter>
    //   <nav>
    //     <NavLink to="/" activeClassName="active">Christmas 2023</NavLink>
    //   </nav>
    //   <Routes>
    //     <Route path="/" element={<Christmas2023 />} />
    //   </Routes>
    // </BrowserRouter>
    <div className="App">
      <header className="App-header">
        <img src="https://abchristmas2.s3.us-west-2.amazonaws.com/Valentines_2024.PNG" className="App-logo" alt="QR Code" />
        <p>
          Happy Valentine's Day to my beatiful queen!
          <br />
          We're barely 2 months into 2024, and it's already turning into the best year ever! You have 
          grown so much right in front of my eyes, and I'm so proud that you never give up! We 
          wouldn't be where we are today without your unstoppable commitment to our family. 
          <br />
          Just like last Christmas, the B-Squad collaborated presents for our queen. You can tap on 
          each of our little puppies to see what they got you. We love you more than everything, Ashlea!
          <br />
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: "1" }} >              
              <a className='App-link' target="_blank" rel="noreferrer" href="https://www.amazon.com/gp/product/B0C79XTWWM?ref=ppx_pt2_dt_b_prod_image&th=1">
                <div>
                  <img src="https://abchristmas2.s3.us-west-2.amazonaws.com/Beau_Valentines_2024.PNG" className="App-logo" alt="Beau" />
                </div>
              </a>
            </div>
            <div style={{ flex: "1" }}>
              <a className='App-link' target="_blank" rel="noreferrer" href="https://skims.com/">
                <div>
                  <img src="https://abchristmas2.s3.us-west-2.amazonaws.com/Bleu_Valentines_2024.PNG" className="App-logo" alt="Bleu" />
                </div>
              </a>
            </div>
          </div>
          <br />
          <br />
          Love,
          <br />
          Brian, Beau & Bleu
        </p>
      </header>
    </div>
  );
}

export default App;
